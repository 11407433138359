import React, { useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"

// Components
import Accordion from "@components/accordion"
import Anchor from "@components/Anchor"
import Container from "@components/container"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Callout from "@components/callout"
import PatientVoice from "@components/PatientVoice"
import { heading1, dashedFocusTight } from "@styles/ui"

const Faq = ({ location, data }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleAccordian = () => {
    setExpanded(!expanded)
  }

  return (
    <Layout location={location} mainBgColour="lilac" isiBgColour="white">
      <Seo
        title="XYWAV® and Idiopathic Hypersomnia (IH) FAQs | XYWAV for IH"
        description="Find frequently asked questions about Idiopathic Hypersomnia (IH) and XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse"
        location={location}
      />

      <section tw="bg-lilac relative pt-9 mb-0 md:pt-16 lg:(pt-24 pb-8 mb-0)">
        <Container>
          <img
            tw="hidden absolute h-auto z-0 top-0 -right-[282px] lg:(block w-[630px])"
            src={"/images/xywav-logo.svg"}
            alt=""
          />
          <h1 css={[heading1, tw`relative max-w-3xl pt-6 text-merlin`]}>
            XYWAV and Idiopathic Hypersomnia (IH) FAQs
          </h1>
        </Container>
      </section>

      <section>
        <div tw="mt-[1.875rem] mb-8 w-full lg:(mt-0 mb-20)">
          <div tw="rounded-lg w-full bg-white flex flex-col relative z-10 max-w-5xl m-auto pt-6 pb-[3.125rem] px-4 xl:(pt-7 pb-10 px-24 max-w-6xl)">
            <button
              css={[
                tw`inline-block underline font-extrabold text-studio mb-8 self-end text-sm md:(text-xl)`,
                dashedFocusTight,
              ]}
              onClick={toggleAccordian}
            >
              {!expanded ? "Expand All +" : "Collapse All -"}
            </button>
            {faqs.map((item, i) => {
              return (
                <Accordion
                  ih
                  key={i}
                  heading={item.heading}
                  content={item.content}
                  expandAllToggle={expanded}
                />
              )
            })}
          </div>
        </div>
      </section>

      {/* Call out */}
      <Callout
        heading="Stay in the loop!"
        cta={{
          text: "SIGN UP NOW",
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            We&apos;re here to support you with information on Idiopathic
            Hypersomnia, starting XYWAV, tips for talking with your doctor,
            JazzCares<sup>&reg;</sup>, and more. Join our email list today.
          </p>
        </>
      </Callout>

      {/* Quote */}
      <PatientVoice
        quote={
          <>
            Starting XYWAV was an adjustment, but I managed it by working with
            my doctor and sticking to the routine that we set together. Now, I
            am a XYWAV mentor to share my experience with others.&rdquo;
          </>
        }
        patient={"Cindy"}
        diagnosis={"XYWAV IH Ambassador"}
        imageData={data}
        altText={"Patient Cindy shares her XYWAV® story"}
        cta={{
          text: (
            <>
              Stay connected and learn more about the XYWAV
              <br tw="hidden lg:block xl:hidden" /> Mentor Program
            </>
          ),
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
        theme="ih"
      />

      {/* Resources callout */}
      <Callout
        heading="Keep learning about IH and XYWAV"
        cta={{
          text: "Get resources",
          url: "/idiopathic-hypersomnia/resources/",
        }}
      >
        <p>
          Visit the resources page to find information to download, patient
          testimonials, and myWAV app information.
        </p>
      </Callout>
    </Layout>
  )
}

const faqs = [
  {
    heading: "What is Idiopathic Hypersomnia?",
    content: (
      <>
        <p>
          Idiopathic Hypersomnia is a rare central sleep disorder. Idiopathic
          means the cause is unknown. Central means it is related to how the
          brain works.
        </p>
        <p>
          Some key symptoms of Idiopathic Hypersomnia are feeling very sleepy
          during the day; experiencing sleep inertia (sleep drunkenness); and
          long, unrefreshing sleep. People living with Idiopathic Hypersomnia
          still experience these symptoms despite sleeping a typical (or more
          than typical) amount each night.
        </p>

        <Anchor link="/idiopathic-hypersomnia/what-is-ih/" hasArrow bold>
          Learn more about Idiopathic Hypersomnia
        </Anchor>
      </>
    ),
  },
  {
    heading: <>What is XYWAV?</>,
    content: (
      <>
        <p>
          XYWAV is the first and only FDA-approved treatment for Idiopathic
          Hypersomnia. It is indicated for adults living with Idiopathic
          Hypersomnia.
        </p>

        <Anchor
          link="/pdf/XYWAV_IH_Brochure.pdf"
          newTab
          hasArrow
          bold
          addedStyles={tw`mb-4`}
        >
          Download the XYWAV Idiopathic Hypersomnia brochure to learn more
        </Anchor>
        <br />
        <Anchor link="/idiopathic-hypersomnia/what-is-xywav/" hasArrow bold>
          Learn more about XYWAV
        </Anchor>
      </>
    ),
  },
  {
    heading: <>How is XYWAV thought to work?</>,
    content: (
      <>
        <p>
          XYWAV is thought to work during sleep to help with the symptoms of
          Idiopathic Hypersomnia during the day. The exact way XYWAV works for
          the treatment of Idiopathic Hypersomnia is unknown.
        </p>
      </>
    ),
  },
  {
    heading: (
      <>
        How was the efficacy of XYWAV studied in people living with Idiopathic
        Hypersomnia?
      </>
    ),
    content: (
      <>
        <p>
          The efficacy of XYWAV was studied in 115 people aged 19-75 with
          Idiopathic Hypersomnia. The XYWAV Idiopathic Hypersomnia clinical
          study was a double-blind, randomized withdrawal trial. That means all
          people in the clinical study started treatment with XYWAV. Some
          participants continued taking their stimulant or{" "}
          <span tw="whitespace-nowrap">wake-promoting</span> agent along with
          XYWAV. They all adjusted XYWAV to a stable dose (the dose that worked
          best for them). Then they were randomly assigned to continue treatment
          with XYWAV or switched to placebo for 2 weeks. Neither the clinical
          investigators nor the people in the study knew who got XYWAV or who
          got placebo.
        </p>
        <span>
          <strong>
            {" "}
            <Anchor link="/idiopathic-hypersomnia/xywav-efficacy/" hasArrow>
              Learn more about study results with XYWAV in Idiopathic
              Hypersomnia
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>What are the possible side effects of&nbsp;XYWAV?</>,
    content: (
      <>
        <p>
          The most common side effects in adult patients treated with XYWAV in
          the Idiopathic Hypersomnia clinical study included nausea, headache,
          dizziness, anxiety, insomnia, decreased appetite, excessive sweating
          (hyperhidrosis), vomiting, diarrhea, dry mouth, parasomnia (a sleep
          disorder that can include abnormal dreams, abnormal rapid eye movement
          [REM] sleep, sleep paralysis, sleep talking, sleep terror,
          sleep-related eating disorder, sleepwalking, and other abnormal
          sleep-related events), sleepiness (somnolence), fatigue, and tremor.
          In patients who remained on treatment, side effects tended to occur
          early and diminish over time.
        </p>
        <p>
          These are not all the possible side effects of XYWAV. For more
          information, ask your doctor or pharmacist. Call your doctor for
          medical advice about side effects. You are encouraged to report
          negative side effects of prescription drugs to the FDA. Visit{" "}
          <Anchor link="http://www.fda.gov/medwatch" isExternal newTab>
            www.fda.gov/medwatch
          </Anchor>
          , or call{" "}
          <Anchor link="tel:1-800-FDA-1088" purple>
            <nobr>1-800-FDA-1088</nobr>
          </Anchor>
          .
        </p>{" "}
        <span>
          <strong>
            <Anchor link="/idiopathic-hypersomnia/side-effects/" hasArrow>
              Learn more about the possible side effects with XYWAV in
              Idiopathic Hypersomnia
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>When could I start to see a change in&nbsp;symptoms?</>,
    content: (
      <>
        <p>
          After starting XYWAV, it may take some time for you and your doctor to
          determine the right dose, and to see symptoms improve. Your doctor
          will decide on a starting dose. If needed, they may adjust your dose
          or dosing routine.
        </p>{" "}
      </>
    ),
  },
  {
    heading: <>What does XYWAV contain?</>,
    content: (
      <>
        <p>
          XYWAV oral solution contains oxybate, a central nervous system (CNS)
          depressant. XYWAV contains a mixture of calcium oxybate, magnesium
          oxybate, potassium oxybate, and sodium oxybate. A 9 gram/night dose
          contains 687&nbsp;mg of calcium, 182&nbsp;mg of magnesium, 642&nbsp;mg
          of potassium, and 131&nbsp;mg of sodium. XYWAV also contains an
          artificial sweetener called sucralose. XYWAV does not contain any
          ingredient made from a gluten&#8209;containing grain (wheat, barley,
          or rye).
        </p>
      </>
    ),
  },
  {
    heading: <>How do I take XYWAV?</>,
    content: (
      <>
        {" "}
        <p>
          If your doctor prescribes you nightly XYWAV, they will give you
          detailed instructions on how to take your prescribed dose(s). You will
          also receive additional materials to ensure that you understand how to
          prepare and take your nightly XYWAV dose(s).
        </p>
        <span>
          <strong>
            {" "}
            <Anchor
              link="/idiopathic-hypersomnia/dosing/"
              hasArrow
              addedStyles={tw`mb-4`}
            >
              Learn more about taking XYWAV
            </Anchor>
            <br />
            <Anchor
              link="https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"
              hasArrow
              newTab
            >
              Please refer to the XYWAV Instructions for Use found within the
              Medication Guide for detailed instructions
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: (
      <>What if I have trouble waking up for&nbsp;my second dose of XYWAV?</>
    ),
    content: (
      <>
        <p>
          It&apos;s easy to understand why you might worry about taking your
          second dose. You may want to set an alarm clock to help you wake up
          for your second dose of XYWAV. If you miss your second dose, skip that
          dose. Do not take XYWAV again until the next night. Never take two
          doses of XYWAV at one time.
        </p>
        <span>
          <strong>
            <Anchor
              link="https://pp.jazzpharma.com/pi/xywav.en.MG.pdf"
              hasArrow
              newTab
            >
              Please refer to the XYWAV Instructions for Use found within the
              Medication Guide for detailed instructions
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>Will I be able to wake up in case of an emergency?</>,
    content: (
      <>
        <p>
          If you have questions about how taking XYWAV may impact you, please
          talk to your doctor.
        </p>
      </>
    ),
  },
  {
    heading: <>How much does XYWAV cost?</>,
    content: (
      <>
        <p>
          Jazz Pharmaceuticals is committed to making sure that people who are
          prescribed XYWAV can get it affordably.
        </p>
        <span>
          <strong>
            <Anchor
              link="/idiopathic-hypersomnia/jazzcares-for-xywav/"
              hasArrow
            >
              Learn about financial assistance options for qualified patients
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
  {
    heading: <>What support is available if my doctor prescribes XYWAV?</>,
    content: (
      <>
        <p>
          Jazz Pharmaceuticals, the leader in sleep medicine, is committed to
          helping you get therapy support and assistance throughout your
          treatment journey based on your personal needs every step of the way.
        </p>
        <ul>
          <li>
            <span>
              <strong>
                <Anchor link="/idiopathic-hypersomnia/patient-support-programs/">
                  Nurse and pharmacy support
                </Anchor>
              </strong>
            </span>
            —Your JazzCares<sup>&reg;</sup> Nurse Case Manager will be with you
            from the very beginning in your treatment journey, and a pharmacist
            is available 24/7 to answer questions about your prescription.
          </li>
          <li>
            <span>
              <strong>
                <Anchor link="/idiopathic-hypersomnia/jazzcares-for-xywav/">
                  Access and affordability
                </Anchor>
              </strong>
            </span>
            —The Certified Pharmacy will assist you with navigating the
            insurance process and understanding your insurance coverage to help
            get your treatment started.
          </li>
          <li>
            <Anchor link="/idiopathic-hypersomnia/resources/">
              <span>
                <strong>Personalized resources</strong>
              </span>
            </Anchor>
            —At different points along the way, you will receive relevant tools
            and resources to help as you start and continue your therapy,
            including:
            <ul tw="ml-1.5">
              <li>
                <Anchor
                  isExternal
                  newTab
                  link="https://mywav.page.link/PvFKcSAFNUnXQGr89"
                >
                  myWAV,
                </Anchor>{" "}
                the 24/7 digital tool for personalized support and motivation
              </li>
              <li>
                A Starter Kit to help you get started on your path to your first
                shipment of XYWAV
              </li>
              <li>A Welcome Kit to help manage your XYWAV treatment</li>
              <li>Prescription refill reminders</li>
              <li>
                Information and support resources for caregivers and loved ones
              </li>
            </ul>
          </li>
          <li>
            <span>
              <strong>
                <Anchor link="/idiopathic-hypersomnia/resources/">
                  Helpful Materials
                </Anchor>
              </strong>
            </span>
            —Download the XYWAV Idiopathic Hypersomnia Brochure, Idiopathic
            Hypersomnia Severity Scale (IHSS), Epworth Sleepiness Scale (ESS),
            and Talking With Your Doctor Quiz to learn more about XYWAV and help
            measure your symptoms.
          </li>
        </ul>
      </>
    ),
  },
  {
    heading: <>Why can&apos;t I get XYWAV at my local&nbsp;pharmacy?</>,
    content: (
      <>
        <p>
          Because of the risk of CNS depression, abuse, and misuse, XYWAV is
          available only through a restricted distribution program called the
          XYWAV and XYREM REMS. You must be enrolled in this program to receive
          XYWAV.
        </p>
        <p>
          In addition, the Certified Pharmacy is the only pharmacy in the United
          States that is permitted to fill your XYWAV prescription and send it
          directly to you. Call the Certified Pharmacy at{" "}
          <Anchor tel link="tel:1-866-997-3688">
            1-866-997-3688
          </Anchor>{" "}
          for more information.
        </p>
        <span>
          <strong>
            <Anchor link="/idiopathic-hypersomnia/getting-xywav/" hasArrow>
              Learn more
            </Anchor>
          </strong>
        </span>
      </>
    ),
  },
]

export default Faq

export const query = graphql`
  query {
    patientVoice: file(
      relativePath: { eq: "faq/patient-cindy-ih-ambassador-quote.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 705, placeholder: NONE)
      }
    }
    patientVoiceMobile: file(
      relativePath: { eq: "faq/patient-cindy-ih-ambassador-quote-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
